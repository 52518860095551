import { Slot } from "./Slot";
import moment from "moment";
import { Task } from "../../onethreefive/model/Task";
export class SlotsBoard {
    clearSlotByName(slot) {
        const index = this.list.findIndex((s) => (s.name === slot.name));
        this.clearSlot(index);
    }
    clearSlot(index) {
        this.list[index].reset();
    }
    updateSlot(i, _todo) {
        var _a;
        let task = _todo;
        if (this.list[i].checkListItemId != undefined &&
            this.list[i].checkListItemId != '') {
            const checklistItem = (_a = _todo.getTodoTask().checklistItems) === null || _a === void 0 ? void 0 : _a.find((it) => (it.id == this.list[i].checkListItemId));
            task = Task.makeTask(_todo.getTodoTask(), checklistItem, _todo.listName);
        }
        this.list[i].status = task.getStatus();
        if (task.getStatus() === 'completed') {
            this.list[i].status = 'completed';
            return true;
        }
        if (task.isInTheFuture()) {
            this.list[i].status = 'postponed';
            return true;
        }
        return task.todoTask.recurrence != undefined;
    }
    static createDefaultSlotsBoard() {
        const board = {
            updated: moment(0).toISOString(),
            list: [
                Slot.createSlot(0, ""),
                Slot.createSlot(1, ""),
                Slot.createSlot(2, ""),
                Slot.createSlot(3, ""),
                Slot.createSlot(4, ""),
                Slot.createSlot(5, ""),
                Slot.createSlot(6, ""),
                Slot.createSlot(7, ""),
                Slot.createSlot(8, ""),
            ],
            version: 1
        };
        return new SlotsBoard(board);
    }
    static createSlotsBoard(board) {
        return SlotsBoard.createSlotsBoardFromJson(board);
    }
    static createSlotsBoardFromJson(jsonArray) {
        if (jsonArray == null) {
            return SlotsBoard.createDefaultSlotsBoard();
        }
        if (jsonArray.version == 1) {
            return new SlotsBoard(jsonArray);
        }
        const slotsBoard = SlotsBoard.createDefaultSlotsBoard();
        if (Array.isArray(jsonArray)) {
            slotsBoard.list = jsonArray;
        }
        else if (jsonArray.m_aSlots != undefined && Array.isArray(jsonArray.m_aSlots)) {
            slotsBoard.list = jsonArray.m_aSlots;
        }
        else {
            slotsBoard.list = jsonArray.list;
        }
        return slotsBoard;
    }
    constructor(state) {
        this.list = [];
        if (this.list === undefined) {
            this.list = new Array(9);
        }
        for (let i = 0; i < SlotsBoard.SIZE; ++i) {
            this.list[i] = Slot.copySlot(state.list[i]);
        }
        this.updated = state.updated;
        this.version = state.version;
    }
    addTaskToSlot({ task, bucket }) {
        if (bucket === "one") {
            if (this.isEmpty(0)) {
                return this.updateSlotWithTaskId(0, task.getListName(), task.getId());
            }
        }
        else if (bucket === "three") {
            for (let i = 1; i <= 3; ++i) {
                if (this.isEmpty(i)) {
                    return this.updateSlotWithTaskId(i, task.getListName(), task.getId());
                }
            }
        }
        else if (bucket === "five") {
            for (let i = 4; i < 9; ++i) {
                if (this.isEmpty(i)) {
                    return this.updateSlotWithTaskId(i, task.getListName(), task.getId());
                }
            }
        }
        return this;
    }
    _get(i) {
        if (this.list[i] == undefined) {
            return Slot.createSlot(i, "", null, null, null, undefined, undefined, undefined, undefined);
        }
        return new Slot({
            name: this.list[i].name,
            tid: this.list[i].tid,
            added: moment().toString(),
            title: null,
            status: undefined,
            headerTitle: undefined,
            checkListItemId: undefined,
            type: undefined,
            listName: this.list[i].listName,
        });
    }
    getUpdated() {
        if (this.updated == undefined) {
            this.updated = moment(0).toISOString();
        }
        return this.updated;
    }
    merge(state2) {
        if (state2 == undefined) {
            return;
        }
        if (this.updated == undefined) {
            this.updated = state2.updated;
            this.list = state2.list;
            return;
        }
        if (state2.updated == undefined) {
            return;
        }
        if (moment(this.updated).isBefore(moment(state2.updated))) {
            this.list = state2.list;
            this.updated = state2.updated;
            this.version = state2.version;
        }
    }
    updateSlotWithTaskId(index, taskId, listName) {
        this.list[index] = Slot.createSlot(index, listName, taskId, moment().toString(), null, undefined, undefined, undefined, undefined);
        this.updated = moment().toISOString();
        return this;
    }
    updateSlotWithTodo(slot, todo) {
        this.list.forEach(s => {
            if (s.name === slot.name) {
                if (todo != null) {
                    s.title = todo.getTitle();
                    s.tid = todo.getId();
                }
                s.status = slot.status;
                return null;
            }
        });
        return this;
    }
    isEmpty(i) {
        if (this.list[i] == undefined) {
            return true;
        }
        return (this.list[i] != undefined && this.list[i].tid == undefined);
    }
    isTodoOnBoard(todo) {
        for (let i = 0; i < this.list.length; ++i) {
            let slot = this.list[i];
            if (slot.tid == todo.getId())
                return true;
        }
        return false;
    }
    getSlots() {
        return rawArrayToSlotArray(this.list);
    }
    isTaskOnBoard(t, bucket) {
        var _a;
        for (let i = 0; i < this.list.length; ++i) {
            if (((_a = this.list[i].tid) === null || _a === void 0 ? void 0 : _a.taskId) == t.getId().taskId) {
                if (i == 0) {
                    bucket = { bucket: "one", index: i };
                }
                else if (i < 4) {
                    bucket = { bucket: "three", index: i };
                }
                else if (i < 9) {
                    bucket = { bucket: "five", index: i };
                }
                return true;
            }
        }
        return false;
    }
    object() {
        return JSON.parse(JSON.stringify(this));
    }
    clearSlots() {
        const sb = SlotsBoard.createDefaultSlotsBoard();
        this.list = sb.list;
        this.updated = sb.updated;
    }
    resetSlot(i) {
        this.list[i].reset();
    }
    fillSlot(i, todo) {
        this.list[i].added = (new Date()).toISOString();
        this.list[i].title = todo.getTitle();
        this.list[i].status = todo.getStatus();
        this.list[i].tid = todo.getId();
        this.list[i].headerTitle = todo.getHeaderTitle();
        this.list[i].checkListItemId = todo.getCheckListItemId();
        this.list[i].type = todo.type;
        this.list[i].webLink = todo.getWebLink();
    }
}
SlotsBoard.SIZE = 9;
function rawArrayToSlotArray(aSlots) {
    let slots = new Array(9);
    for (let i = 0; i < aSlots.length; ++i) {
        slots[i] = Slot.createSlot(i, aSlots[i].listName, aSlots[i].tid, aSlots[i].added, aSlots[i].title, aSlots[i].status, aSlots[i].headerTitle, aSlots[i].checkListItemId, aSlots[i].type, aSlots[i].webLink);
    }
    return slots;
}
